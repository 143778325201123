
import { useEffect, useState } from 'react';
import { Add, AddCircle, ArrowBackIos, DeleteOutline, } from '@mui/icons-material'
import { Button, Card, Divider, Grid, Stack, Typography, TextField as Textt, ClickAwayListener, Box, CircularProgress } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createActivityValidationSchema } from 'utils/validations';
import dayjs, { Dayjs } from 'dayjs';
import { TextField, SelectField, DateField, RichTextField, TimeField, MultiSelectTimeField } from 'components';
import { useCreateActivityMutation } from 'apps/dashboard/context';
import { ISessionInput } from 'corede-common-umbrella';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { enqueueSnackbar } from 'notistack';
import { routes } from 'apps/dashboard/routes/routes';
import { departments, categories } from 'utils';



const CreateActivity = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [createActivity, { data, isSuccess, isLoading, isError, error }] = useCreateActivityMutation()
    const [session, setSession] = useState<ISessionInput[]>([])
    const [valueStartTime, setValueStartTime] = useState<Dayjs | null>(null);
    const [openStartTime, setOpenStartTime] = useState(false);
    const [valueEndTime, setValueEndTime] = useState<Dayjs | null>(null);
    const [openEndTime, setOpenEndTime] = useState(false);



    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            title: '',
            category: '',
            letsTitle: '',
            quota: null,
            date: null,
            reservationCloseDate: null,
            location: '',
            departments: [],
            reserveQuota: null,
            details: ''
        },
        validationSchema: createActivityValidationSchema,
        onSubmit: values => {
            console.log(values, 'values')
            createActivity({
                title: values.title,
                mainQuota: Number(values.quota) ?? 0,
                reserveQuota: Number(values.reserveQuota) ?? 0,
                categories: values.letsTitle,
                date: values.date ?? new Date(),
                reservationCloseDate: values.reservationCloseDate ?? new Date(),
                location: values.location,
                details: values.details,
                letsTitle: values.letsTitle,
                sessions: session
            })
        }
    })

    const sessionFormik = useFormik({
        initialValues: {
            sessionName: '',
            sessionTimeInterval: '',
            sessionAllowedDepartments: []
        },
        onSubmit: values => {
            setSession([...session, values])
        }
    })

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Activity has been created successfully', { variant: 'success' });
            navigate(routes.admin.activities)
        }
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar((error as any).error.message.en, { variant: 'error' })
        }
    }, [isError, error])



    return <DashboardLayout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />
                        <h1>Create Activity</h1>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} xl={11}>

                <Card sx={{ p: 5, mt: 2 }}>
                    <Typography variant="h6">Activity Information</Typography>
                    <Divider />
                    <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Title"
                                values={values}
                                name={"title"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Category"
                                select={true}
                                values={values}
                                name={"letsTitle"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                datas={categories}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Location"
                                values={values}
                                name={"location"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Quota"
                                variant="outlined"
                                isNumber
                                values={values}
                                name={"quota"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Reserve Quota"
                                variant="outlined"
                                values={values}
                                isNumber
                                name={"reserveQuota"}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Activity Date"
                                values={values}
                                minDate={dayjs(new Date())}
                                maxDate={dayjs(new Date()).add(1, 'year')}
                                name="date"
                                setFieldValue={setFieldValue}
                                
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <DateField
                                label="Reservation Close Date"
                                values={values}
                                minDate={dayjs(new Date())}
                                maxDate={values.date ?? dayjs(new Date()).add(-1, 'hours')}
                                name="reservationCloseDate"
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <RichTextField
                                values={values}
                                name={"details"}
                                setFieldValue={setFieldValue}
                                placeholder="Details..."
                            />
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Typography variant="h6" mt={5}>Sessions</Typography>
                            <Divider />
                        </Grid>

                        <Grid item xs={12}>
                            {session.map((item, index) =>
                                <Card sx={{ p: 1, borderRadius: 2, bgcolor: '#F4F6F8', display: "flex", justifyContent: 'space-between', alignItems: 'center', mb: 2, cursor: 'pointer', border: "1px solid #ccc" }} key={index}>
                                    <Box p={2} borderRadius={2} sx={{ width: '100%' }} display={'flex'} flexDirection={'row'} gap={2} >
                                        <Typography fontWeight={'bold'}>{item.sessionName}</Typography>
                                        <Typography>{item.sessionTimeInterval}</Typography>
                                        {item.sessionAllowedDepartments.map((item, index) => <Box sx={{ bgcolor: '#dedede', borderRadius: 2, px: 1 }} key={index}>
                                            <Typography >{item.toUpperCase()}</Typography>
                                        </Box>)

                                        }
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size='small'
                                        onClick={() => {
                                            setSession(session.filter((_, i) => i !== index))
                                        }}
                                    >
                                        <DeleteOutline />
                                    </Button>

                                </Card>
                            )}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Session Name"
                                values={sessionFormik.values}
                                name={"sessionName"}
                                setFieldValue={sessionFormik.setFieldValue}
                                handleBlur={sessionFormik.handleBlur}
                                touched={sessionFormik.touched}
                                errors={sessionFormik.errors}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectField
                                values={sessionFormik.values}
                                name={"sessionAllowedDepartments"}
                                setFieldValue={sessionFormik.setFieldValue}
                                datas={departments}
                                placeholder="Select Departments"
                            />
                        </Grid>

                        <ClickAwayListener onClickAway={() => setOpenStartTime(false)}>
                            <Grid item xs={6} md={4}>
                                <TimeField
                                    label="Start Time"
                                    value={valueStartTime ? dayjs(valueStartTime).format('HH:mm') : ''}
                                    onClick={() => setOpenStartTime(true)}
                                />

                                {openStartTime && <MultiSelectTimeField
                                    value={valueStartTime}
                                    onChange={(newValue) => {
                                        setValueStartTime(newValue)
                                        setValueEndTime(dayjs(newValue).add(1, 'hour'))
                                    }}
                                />}
                            </Grid>
                        </ClickAwayListener>

                        <ClickAwayListener onClickAway={() => setOpenEndTime(false)}>
                            <Grid item xs={6} md={4}>
                                <TimeField
                                    label="End Time"
                                    value={valueEndTime ? dayjs(valueEndTime).format('HH:mm') : ''}
                                    onClick={() => setOpenEndTime(true)}
                                />

                                {openEndTime && <MultiSelectTimeField
                                    minTime={valueStartTime ?? dayjs('1991-05-23T00:00')}
                                    value={valueEndTime}
                                    onChange={(newValue) => setValueEndTime(newValue)}
                                />}
                            </Grid>
                        </ClickAwayListener>

                        <Grid item xs={12} md={4}>
                            <Button
                                variant="outlined"
                                fullWidth
                                color="primary"
                                disabled={!valueStartTime || !valueEndTime || !sessionFormik.values.sessionName || !sessionFormik.values.sessionAllowedDepartments.length}
                                onClick={() => {
                                    setSession([...session, {
                                        sessionName: sessionFormik.values.sessionName,
                                        sessionTimeInterval: `${dayjs(valueStartTime).format('HH:mm')}-${dayjs(valueEndTime).format('HH:mm')}`,
                                        sessionAllowedDepartments: sessionFormik.values.sessionAllowedDepartments.map((item: any) => item.value)
                                    }])
                                    sessionFormik.resetForm()
                                    setValueStartTime(null)
                                    setValueEndTime(null)
                                }}
                            > <AddCircle sx={{ mr: 1 }} />
                                Add Session
                            </Button>
                        </Grid>

                        <Grid item xs={12} mt={5}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disabled={isLoading || !values.title || !values.letsTitle || !values.quota || !values.reserveQuota || !values.date || !values.reservationCloseDate || !values.location || !values.details || !session.length}
                                onClick={() => {
                                    handleSubmit()
                                }}
                            >
                                {isLoading ? <CircularProgress size={"1rem"} /> : 'Create Activity'}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default CreateActivity