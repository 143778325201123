import { Add, AddCircle, AddRounded, ArrowBack, ArrowBackIos, CalendarMonth, CategoryOutlined, CheckCircle, Close, Countertops, Delete, DeleteOutline, Description, Details, Edit, Group, Groups, LocalActivity, LocationCity, LockClock, QueryBuilder, RemoveRedEye, RemoveRedEyeOutlined, StarOutlineSharp, TextDecrease, TextSnippet } from '@mui/icons-material'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField as Field, MenuItem, Checkbox, CircularProgress } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useParams, useNavigate } from 'react-router-dom';
import {
    useAddToActivityAsMainParticipantMutation, useAddToActivityMutation, useUpdateActivityParticipatedParticipantsMutation,
    useAdminActivityQuery, useRemoveFromActivityMutation, useSetActivityStatusMutation
} from 'apps/dashboard/context';
import { useLazyUserByAdminQuery } from 'apps/auth/context';
import moment from 'moment';
import parse from 'html-react-parser';
import Lottie from 'lottie-react';
import LetsRelax from 'assets/animations/letsrelax.json';
import LetsTry from 'assets/animations/letstry.json';
import LetsLearn from 'assets/animations/letslearn.json';
import LetsMove from 'assets/animations/letsmove.json';
import LetsConnect from 'assets/animations/letsconnect.json';
import { useEffect, useState } from 'react';
import User from 'assets/user.png';
import { enqueueSnackbar } from 'notistack';
import { useRemoveFromBlacklistMutation, useAddToBlacklistMutation } from 'apps/dashboard/context';
import { useUsersQuery } from 'apps/auth/context';
import { useFormik } from 'formik';
import { ActivityStatus } from 'corede-common-umbrella';
import { activityStatus, categories } from 'utils';
import { TextField } from 'components';
import { routes } from 'apps/dashboard/routes/routes';
import UserBg from 'assets/user-bg.jpg'
import { disableCache } from '@iconify/react';


const ActivityDetail = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const [selectedActivity, setSelectedActivity] = useState<any>(null)
    const [openAddToActivity, setOpenAddToActivity] = useState(false)
    const [openAddToActivityToMain, setOpenAddToActivityToMain] = useState(false)
    const [selectedUserForDelete, setSelectedUserForDelete] = useState<any>(null)
    const [openConfirmParticipants, setOpenConfirmParticipants] = useState(false)
    const [selectedConfirmParticipants, setSelectedConfirmParticipants] = useState<string[]>([]);
    const [getUserByAdmin, { data: userData, }] = useLazyUserByAdminQuery()
    const [removeFromActivity, { isSuccess: isRemovedFromActivity, isLoading: isLoadingRemoveFromActivity, isError: isErrorRemoveFromActivity, error: errorRemoveFromActivity, data: dataRemoveFromActivity }] = useRemoveFromActivityMutation()
    const [addToBlacklist, { isSuccess: isAddedToBlacklist, isLoading: isLoadingAddToBlacklist, isError: isErrorAddToBlacklist, error: errorAddToBlacklist, data: dataAddToBlacklist }] = useAddToBlacklistMutation()
    const [removeFromBlacklist, { isSuccess: isRemovedFromBlacklist, isLoading: isLoadingRemoveFromBlacklist, isError: isErrorRemoveFromBlacklist, error: errorRemoveFromBlacklist, data: dataRemoveFromBlacklist }] = useRemoveFromBlacklistMutation()
    const { data, error, isLoading, refetch } = useAdminActivityQuery({ id: id ?? '' })
    const { data: usersData, isLoading: usersLoading, error: usersError } = useUsersQuery({})
    const [setActivityStatus, { isLoading: idLoadingUpdate, data: dataUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate }] = useSetActivityStatusMutation()
    const [addToActivity, { isSuccess: isAddedToActivity, isLoading: isLoadingAddToActivity, isError: isErrorAddToActivity, error: errorAddToActivity, data: dataAddToActivity }] = useAddToActivityMutation()
    const [addToActivityToMain, { isSuccess: isAddedToActivityToMain, isLoading: isLoadingAddToActivityToMain, isError: isErrorAddToActivityToMain, error: errorAddToActivityToMain, data: dataAddToActivityToMain }] = useAddToActivityAsMainParticipantMutation()
    const [updateActivityParticipatedParticipants, { isSuccess: isSuccessUpdateParticipatedParticipants, isLoading: isLoadingUpdateParticipatedParticipants, isError: isErrorUpdateParticipatedParticipants, error: errorUpdateParticipatedParticipants }] = useUpdateActivityParticipatedParticipantsMutation()

    const handleBlackList = () => {
        if (selectedUser?.isBlacklisted) {
            removeFromBlacklist({ userId: selectedUser?._id })
        } else {
            addToBlacklist({ userId: selectedUser?._id ?? '' })
        }
    }


    const handleUserDetail = (user: any) => {
        getUserByAdmin({ userId: user._id }).then((res: any) => {
            console.log(res, 'res')
            setSelectedUser(res?.data)
        })
    }

    const handleRemoveFromActivity = (userId: string) => {
        console.log('remove')
        removeFromActivity({ _id: data?._id ?? '', userId: userId })
    }

    const { values, handleSubmit, handleBlur, setFieldValue, errors, touched, resetForm } = useFormik({
        initialValues: {
            status: '' // Set a default value for status
        },
        onSubmit: values => {
            setActivityStatus({ status: values.status as ActivityStatus, activitySessionId: selectedActivity?._id })
            console.log(values, 'valuessssss')
        }
    })

    const formikForAddActivity = useFormik({
        initialValues: {
            userId: "",
            activityId: id ?? ""
        },
        onSubmit: values => {
            addToActivity({ userId: values.userId, activityId: values.activityId })
        }
    })

    const formikForAddActivityToMain = useFormik({
        initialValues: {
            userId: "",
            activityId: id ?? ""
        },
        onSubmit: values => {
            addToActivityToMain({ userId: values.userId, activityId: values.activityId })
        }
    })

    const handleConfirmParticipants = () => {
        console.log(selectedConfirmParticipants, 'selectedConfirmParticipants')
        updateActivityParticipatedParticipants({ activityId: id ?? "", userIds: selectedConfirmParticipants })
    }

    const handleSelectParticipant = (id: string) => {
        setSelectedConfirmParticipants((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((participantId) => participantId !== id)
                : [...prevSelected, id]
        );
    };

    useEffect(() => {
        if (selectedActivity?.status) {
            console.log(selectedActivity?.status, 'selectedActivity?.status')
            setFieldValue('status', selectedActivity?.status)
        }
    }, [selectedActivity])

    useEffect(() => {
        if (isSuccessUpdate) {
            enqueueSnackbar('Activity status updated successfully', { variant: 'success' })
            setSelectedActivity(null)
            resetForm()
        }
    }, [isSuccessUpdate])

    useEffect(() => {
        if (isSuccessUpdateParticipatedParticipants) {
            enqueueSnackbar('Participants updated successfully', { variant: 'success' })
            setOpenConfirmParticipants(false)
            setSelectedConfirmParticipants([])
        }
    }, [isSuccessUpdateParticipatedParticipants])

    useEffect(() => {
        if (isErrorUpdateParticipatedParticipants) {
            enqueueSnackbar((error as any)?.error?.message?.en || 'Participants update failed', { variant: 'error' })
        }
    }, [errorUpdateParticipatedParticipants])

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar((error as any)?.error?.message?.en || 'Activity status update failed', { variant: 'error' })
        }
    }, [isErrorUpdate])

    useEffect(() => {
        if (isRemovedFromActivity) {
            enqueueSnackbar('User removed from activity', { variant: 'success' })
            console.log('removed')
            setSelectedUserForDelete(null)
            refetch()
        }
    }, [isRemovedFromActivity])

    useEffect(() => {
        if (errorRemoveFromActivity) {
            enqueueSnackbar('Error while removing user from activity', { variant: 'error' })
            console.log('errorRemoveFromActivity', errorRemoveFromActivity)
        }
    }, [errorRemoveFromActivity])

    useEffect(() => {
        if (isAddedToBlacklist) {
            enqueueSnackbar('User added to blacklist', { variant: 'success' })
            console.log('added')
            setSelectedUser(null)
        }
    }, [isAddedToBlacklist])

    useEffect(() => {
        if (errorAddToBlacklist) {
            enqueueSnackbar('Error while adding user to blacklist', { variant: 'error' })
            console.log('errorAddToBlacklist', errorAddToBlacklist)
        }
    }, [errorAddToBlacklist])

    useEffect(() => {
        if (isRemovedFromBlacklist) {
            enqueueSnackbar('User removed from blacklist', { variant: 'success' })
            console.log('removed')
            setSelectedUser(null)
        }
    }, [isRemovedFromBlacklist])

    useEffect(() => {
        if (errorRemoveFromBlacklist) {
            enqueueSnackbar('Error while removing user from blacklist', { variant: 'error' })
            console.log('errorRemoveFromBlacklist', errorRemoveFromBlacklist)
        }
    }, [errorRemoveFromBlacklist])


    useEffect(() => {
        if (isAddedToActivity) {
            enqueueSnackbar('User added to activity', { variant: 'success' })
            console.log('added')
            setSelectedUser(null)
            setOpenAddToActivity(false)
            formikForAddActivity.resetForm()
        }
    }, [isAddedToActivity])

    useEffect(() => {
        if (errorAddToActivity) {
            enqueueSnackbar((errorAddToActivity as any).error?.message?.en || 'Error while adding user to activity', { variant: 'error' })
        }
    }, [errorAddToActivity])

    useEffect(() => {
        if (isAddedToActivityToMain) {
            enqueueSnackbar('User added to activity', { variant: 'success' })
            console.log('added')
            setSelectedUser(null)
            setOpenAddToActivityToMain(false)
            formikForAddActivityToMain.resetForm()
        }
    }, [isAddedToActivityToMain])

    useEffect(() => {
        if (errorAddToActivityToMain) {
            console.log((errorAddToActivityToMain as any).error?.message?.en || 'Error while adding user to activity')
            enqueueSnackbar((errorAddToActivityToMain as any).error?.message?.en || 'Error while adding user to activity', { variant: 'error' })
        }
    }, [errorAddToActivityToMain])


    useEffect(() => {
        if (openConfirmParticipants) {
            setSelectedConfirmParticipants(data?.participatedParticipants?.map((participant: any) => participant._id) || [])
        }
    }, [openConfirmParticipants])

    return <DashboardLayout>
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />
                        <h1>Activity Detail</h1>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="secondary" onClick={() => navigate(routes.admin.updateActivity.replace(':id', data?._id + ''))} >Update General</Button>
                        <Button variant="outlined" color="secondary" onClick={() => navigate(routes.admin.updateActivitySession.replace(':id', data?._id + ''))} >Update Session</Button>
                        <Button variant="contained" color="primary" onClick={() => setSelectedActivity(data)}>
                            Change Status</Button>
                    </Stack>

                </Stack>
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Card sx={{ position: 'relative' }}>
                            <Stack spacing={1} p={3}>
                                <Typography variant="h3">{data?.title}</Typography>
                                <Divider sx={{ width: '50%' }} />
                                <Box sx={{ height: 10 }} />
                                <Stack direction={{ xs: 'column', md: 'row-reverse' }} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                                    <Stack spacing={1} p={3} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                        <Box sx={{ width: 300 }}>
                                            {data?.letsTitle === "letsRelax" && <Lottie animationData={LetsRelax} loop={true} />}
                                            {data?.letsTitle === "letsTry" && <Lottie animationData={LetsTry} loop={true} />}
                                            {data?.letsTitle === "letsLearn" && <Lottie animationData={LetsLearn} loop={true} />}
                                            {data?.letsTitle === "letsMove" && <Lottie animationData={LetsMove} loop={true} />}
                                            {data?.letsTitle === "letsConnect" && <Lottie animationData={LetsConnect} loop={true} />}
                                        </Box>
                                    </Stack>
                                    <Stack spacing={1} p={3}>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CategoryOutlined />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Category: </Typography>
                                            <Typography variant="subtitle1">{categories.find((category: any) => category.value === data?.letsTitle)?.label}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <LocationCity />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Location: </Typography>
                                            <Typography variant="subtitle1">{data?.location}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <TextSnippet />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Name: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionName}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Group />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Allowed Departments: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionAllowedDepartments.join(', ')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <LockClock />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Session Time Interval: </Typography>
                                            <Typography variant="subtitle1">{data?.sessionTimeInterval}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Countertops />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Main Quota: </Typography>
                                            <Typography variant="subtitle1">{data?.mainQuota}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <Countertops />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Reserve Quota: </Typography>
                                            <Typography variant="subtitle1">{data?.reserveQuota}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CalendarMonth />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Activity Date: </Typography>
                                            <Typography variant="subtitle1">{moment(data?.date).format('DD MMM YYYY')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <CalendarMonth />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Close Date: </Typography>
                                            <Typography variant="subtitle1">{moment(data?.reservationCloseDate).format('DD MMM YYYY')}</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={2} alignItems={'center'}>
                                            <StarOutlineSharp />
                                            <Typography variant="subtitle1" fontWeight={"bold"}>Status: </Typography>
                                            <Typography variant="subtitle1">{data?.status?.slice(0, 1).toUpperCase()}{data?.status?.slice(1)}</Typography>
                                        </Stack>
                                    </Stack>

                                </Stack>
                                <Stack direction="row" spacing={2} alignItems={'center'}>
                                    <Description />
                                    <Typography variant="subtitle1" fontWeight={"bold"}>Details: </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems={'center'} sx={{ pl: 5 }}>
                                    <Typography variant="subtitle1">{parse(data?.details ?? '<p></p>')}</Typography>
                                </Stack>
                            </Stack>
                            {/* <LocalActivity sx={{ position: 'absolute', top: -10, right: 0, fontSize: 150, color: '#00000011' }} /> */}

                        </Card>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: -2, mb: -4 }}>
                        <Stack direction="row" alignItems={'center'} justifyContent={data?.status === "pending" ? "flex-end" : "flex-start"} gap={2}>
                            {data?.status === "scheduled" && <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setOpenAddToActivity(true)}
                            >
                                <AddCircle sx={{ mr: 1 }} />
                                Add User
                            </Button>}
                            {data?.status === "pending" && <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setOpenAddToActivityToMain(true)}
                            >
                                <AddCircle sx={{ mr: 1 }} />
                                Add User to Main
                            </Button>}
                            {data?.status === "completed" && <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setOpenConfirmParticipants(true)}
                            >
                                <CheckCircle sx={{ mr: 1 }} />
                                {data?.participatedParticipants?.length === 0 ? "Confirm Participants" : "Update Participants"}
                            </Button>}
                        </Stack>
                    </Grid>

                    {(data as any)?.participatedParticipants?.length > 0 && <Grid item xs={12}>
                        <Card sx={{ p: 3, position: 'relative' }}>
                            <Typography variant="h4" sx={{ mb: 3 }}>Participated Participants</Typography>
                            {!!(data?.participatedParticipants && data?.participatedParticipants?.length > 0)
                                ? <ParticipantsTable type="application" status={data?.status} data={data?.participatedParticipants || []} handleUserDetail={handleUserDetail} handleRemoveFromActivity={setSelectedUserForDelete} />
                                : <Typography variant="body1" textAlign="center"><b>There is no participatedParticipants</b></Typography>}
                            <CheckCircle sx={{ position: 'absolute', top: 10, right: 20, fontSize: 100, color: '#00000011' }} />
                        </Card>
                    </Grid>}

                    <Grid item xs={12} md={6}>
                        <Card sx={{ p: 3, position: 'relative' }}>
                            <Typography variant="h4" sx={{ mb: 3 }}>Applications</Typography>
                            {!!(data?.participants && data?.participants?.length > 0)
                                ? <ParticipantsTable type="application" status={data?.status} data={data?.participants || []} handleUserDetail={handleUserDetail} handleRemoveFromActivity={setSelectedUserForDelete} />
                                : <Typography variant="body1" textAlign="center"><b>There is no applications</b></Typography>}
                            <Groups sx={{ position: 'absolute', top: -10, right: 20, fontSize: 100, color: '#00000011' }} />
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card sx={{ p: 3, position: 'relative' }}>
                                    <Typography variant="h4" sx={{ mb: 3 }}>Participants (Main)</Typography>
                                    {!!(data?.mainParticipants && data?.mainParticipants?.length > 0)
                                        ? <ParticipantsTable type="main" status={data?.status} data={data?.mainParticipants || []} handleUserDetail={handleUserDetail} handleRemoveFromActivity={setSelectedUserForDelete} />
                                        : <Typography variant="body1" textAlign="center"><b>There is no main participants</b></Typography>}
                                    <QueryBuilder sx={{ position: 'absolute', top: 10, right: 20, fontSize: 80, color: '#00000011' }} />
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Card sx={{ p: 3, position: 'relative' }}>
                                    <Typography variant="h4" sx={{ mb: 3 }}>Participants (Reserve)</Typography>
                                    {!!(data?.reserveParticipants && data?.reserveParticipants?.length > 0)
                                        ? <ParticipantsTable type="reserved" status={data?.status} data={data?.reserveParticipants || []} handleUserDetail={handleUserDetail} handleRemoveFromActivity={setSelectedUserForDelete} />
                                        : <Typography variant="body1" textAlign="center"><b>There is no reserve participants</b></Typography>}

                                    <QueryBuilder sx={{ position: 'absolute', top: 10, right: 20, fontSize: 80, color: '#00000011' }} />
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >

        <Dialog open={selectedUser !== null} onClose={() => setSelectedUser(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                User Detail
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: '100%', md: '500px' }, position: 'relative' }}>
                <img
                    src={UserBg}
                    alt="profileBg" style={{ width: '100%', height: "120px", objectFit: 'cover', borderRadius: 20 }} />
                <img src={userData?.profileImage?.publicUrl?.includes('http') ? userData?.profileImage?.publicUrl : User}
                    alt='avatar'
                    style={{ width: 100, height: 100, borderRadius: '50px', objectFit: 'cover', border: '5px solid white', position: 'absolute', top: 10, left: 40, backgroundColor: 'white' }} />
                <Typography variant='h4' mt={3} textAlign="center">
                    {userData?.name} {userData?.surname}
                </Typography>
                <Typography variant='body1' textAlign="center" color="text.secondary">
                    {userData?.email}
                </Typography>
                <Stack direction={{ xs: 'column', md: "row" }} alignItems="flex-start" >
                    <Stack direction={"column"} gap={1} px={1} mt={3} width={{ xs: '100%', md: '50%' }}>
                        <Typography>
                            <strong>Department:</strong> {userData?.department ?? ' -'} <br />
                            <strong>Gender:</strong> {userData?.gender ?? ' -'} <br />
                            <strong>Birth Date:</strong> {userData?.birthDate ? moment(userData?.birthDate).format('DD MMMM YYYY') : ' -'} <br />
                            {/* <strong>Phone Number:</strong> {userData?.phoneNumber ?? ' -'} <br /> */}
                        </Typography>
                    </Stack>
                    <Divider orientation="vertical" flexItem sx={{ mt: 3 }} />
                    <Stack direction={"column"} gap={1} px={3} mt={3} width={{ xs: '100%', md: '50%' }}>
                        <Typography>
                            <strong>Country:</strong> {userData?.country ?? ' -'} <br />
                            <strong>City:</strong> {userData?.city ?? ' -'} <br />
                            <strong>Address:</strong> {userData?.address ?? ' -'} <br />
                        </Typography>
                    </Stack>
                </Stack>
                <Stack px={1} mt={3}>
                    <strong>Description:</strong>
                    <Box sx={{ width: '100%', bgcolor: '#eee', borderRadius: 2, px: 5, py: 2, mb: 2 }}>
                        <Typography> {parse(userData?.description ?? ' -')}</Typography>
                    </Box>
                    <Typography>  <strong>Status:</strong> {userData?.status?.toUpperCase()} </Typography>
                    <Typography>  <strong>Last Login:</strong>  {userData?.lastLoginDate ? moment(userData?.lastLoginDate).format('DD MMMM YYYY - HH:mm') : ' -'} </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedUser(null)}>Cancel</Button>
                <Button
                    variant={!selectedUser?.isBlacklisted ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleBlackList()}
                    disabled={isLoadingAddToBlacklist || isLoadingRemoveFromBlacklist}
                >
                    {isLoadingAddToBlacklist || isLoadingRemoveFromBlacklist ? <CircularProgress size={20} />
                        :
                        !selectedUser?.isBlacklisted ? 'Add to Blacklist' : 'Remove from Blacklist'}
                </Button>
            </DialogActions>
        </Dialog >

        <Dialog open={selectedActivity !== null} onClose={() => setSelectedActivity(null)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                Change Activity Status
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography textAlign={"center"} variant={"body1"} mb={2}>
                    What would you like to do to the status <br />of the activity named <b>{selectedActivity?.title}</b>?
                </Typography>
                {values?.status && <TextField
                    label="Status"
                    name="status"
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    select
                    datas={activityStatus}
                />}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedActivity(null)}>Cancel</Button>
                <Button variant='contained' color="secondary" disabled={!values.status || idLoadingUpdate}
                    onClick={() => handleSubmit()}>
                    {idLoadingUpdate ? <CircularProgress size={20} /> : 'Change Status'}</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openAddToActivity} onClose={() => setOpenAddToActivity(false)}>
            <DialogTitle>
                Add To Activity
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Typography textAlign={"center"} variant={"body1"} mb={2}>
                    Select the user you want to add to the activity.
                </Typography>
                {usersData?.data && <Field
                    fullWidth
                    label={"User"}
                    value={formikForAddActivity.values?.userId}
                    onChange={e => formikForAddActivity.setFieldValue('userId', e.target.value)}
                    onBlur={formikForAddActivity.handleBlur}
                    error={formikForAddActivity.touched?.userId && formikForAddActivity.errors?.userId ? true : false}
                    helperText={formikForAddActivity.touched?.userId && formikForAddActivity.errors?.userId ? formikForAddActivity.errors?.userId : null}
                    select
                >
                    {usersData?.data?.map(option => (
                        <MenuItem key={option._id} value={option._id}>
                            {option.name} {option.surname}
                        </MenuItem>
                    ))}
                </Field>
                }


            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenAddToActivity(false)}>Cancel</Button>
                <Button variant='contained' color="secondary"
                    disabled={isLoadingAddToActivity}
                    onClick={() => formikForAddActivity.handleSubmit()}>
                    {isLoadingAddToActivity ? <CircularProgress size="1rem" /> : "Add to Activity"}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openAddToActivityToMain} onClose={() => setOpenAddToActivityToMain(false)}>
            <DialogTitle>
                Add to Activity as Main Participant
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ maxWidth: "400px" }} >
                <Typography textAlign={"center"} variant={"body1"} mb={2}>
                    Select the user you want to add to the activity as main participant.
                </Typography>
                {usersData?.data && <Field
                    fullWidth
                    label={"User"}
                    value={formikForAddActivityToMain.values?.userId}
                    onChange={e => formikForAddActivityToMain.setFieldValue('userId', e.target.value)}
                    onBlur={formikForAddActivityToMain.handleBlur}
                    error={formikForAddActivityToMain.touched?.userId && formikForAddActivityToMain.errors?.userId ? true : false}
                    helperText={formikForAddActivityToMain.touched?.userId && formikForAddActivityToMain.errors?.userId ? formikForAddActivityToMain.errors?.userId : null}
                    select
                >
                    {usersData?.data?.map(option => (
                        <MenuItem key={option._id} value={option._id}>
                            {option.name} {option.surname}
                        </MenuItem>
                    ))}
                </Field>
                }


            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenAddToActivityToMain(false)}>Cancel</Button>
                <Button variant='contained' color="secondary" onClick={() => formikForAddActivityToMain.handleSubmit()}
                    disabled={isLoadingAddToActivityToMain}>
                    {isLoadingAddToActivityToMain ? <CircularProgress size="1rem" /> : "Add to Activity"}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={selectedUserForDelete} onClose={() => setSelectedUserForDelete(null)}>
            <DialogTitle>
                Are you sure?
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ maxWidth: "400px" }} >
                <Typography textAlign={"center"} variant={"body1"} mb={1}>
                    Are you sure you want to remove from the main participant?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSelectedUserForDelete(null)}>Cancel</Button>
                <Button variant='contained' color="error" onClick={() => handleRemoveFromActivity(selectedUserForDelete)}
                    disabled={isLoadingRemoveFromActivity}>
                    {isLoadingRemoveFromActivity ? <CircularProgress size="1rem" /> : "Remove User"}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openConfirmParticipants} onClose={() => setOpenConfirmParticipants(false)}
            sx={{
                '& .MuiDialog-paper': {
                    padding: 1,
                    borderRadius: 2
                }
            }}>
            <DialogTitle>
                Confirm Participants
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: '100%', md: '500px' }, position: 'relative' }}>
                {!!(data?.mainParticipants && data?.mainParticipants?.length > 0)
                    ? <ParticipantsTable type="selectConfirm" data={data?.mainParticipants || []} handleUserDetail={handleUserDetail} handleRemoveFromActivity={setSelectedUserForDelete} selectedConfirmParticipants={selectedConfirmParticipants} handleSelectParticipant={handleSelectParticipant} />
                    : <Typography variant="body1" textAlign="center"><b>There is no main participants</b></Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenConfirmParticipants(false)}>Cancel</Button>
                <Button
                    variant={!selectedUser?.isBlacklisted ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleConfirmParticipants()}
                    disabled={isLoadingUpdateParticipatedParticipants}
                >
                    {isLoadingUpdateParticipatedParticipants ? <CircularProgress size="1rem" /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog >

    </DashboardLayout >
}

export default ActivityDetail


const ParticipantsTable = ({ data, status, handleUserDetail, handleRemoveFromActivity, type, selectedConfirmParticipants, handleSelectParticipant }: any) => {


    return <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align='center'>Department</TableCell>
                    <TableCell align='center'>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {data?.map((user: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{user?.name} {user?.surname}</TableCell>
                        <TableCell align='center'>{user?.department}</TableCell>
                        <TableCell align='center'>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={'center'}>
                                {type !== "selectConfirm" && <IconButton color="primary" size="small" onClick={() => handleUserDetail(user)}><RemoveRedEyeOutlined /></IconButton>}
                                {(type === "main" && status === "pending") && <IconButton color="error" size="small" onClick={() => handleRemoveFromActivity(user?._id)}><Close /></IconButton>}
                                {(type === "application" && status === "scheduled") && <IconButton color="error" size="small" onClick={() => handleRemoveFromActivity(user?._id)}><Close /></IconButton>}
                                {type === "selectConfirm" && (
                                    <Checkbox
                                        checked={selectedConfirmParticipants?.includes(user._id)}
                                        onChange={() => handleSelectParticipant(user._id)}
                                    />
                                )}
                            </Stack>
                        </TableCell>
                    </TableRow>
                ))}

            </TableBody>
        </Table>
    </TableContainer >
}
