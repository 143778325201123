
import { useEffect, useState, MouseEvent } from 'react';
import { Add, AddCircle, ArrowBackIos, DeleteOutline, Update, } from '@mui/icons-material'
import { Button, Card, Divider, Grid, Stack, Typography, TextField as Textt, ClickAwayListener, Box, CircularProgress } from '@mui/material'
import DashboardLayout from 'apps/dashboard/layouts/DashboardLayout'
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createActivityValidationSchema } from 'utils/validations';
import dayjs, { Dayjs } from 'dayjs';
import { TextField, SelectField, DateField, RichTextField, TimeField, MultiSelectTimeField } from 'components';
import { useCreateActivityMutation, useAdminActivityQuery, useUpdateActivitySessionMutation } from 'apps/dashboard/context';
import { ISessionInput } from 'corede-common-umbrella';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers';
import { enqueueSnackbar } from 'notistack';
import { routes } from 'apps/dashboard/routes/routes';
import { departments, categories } from 'utils';

function formatDateRange(timeRangeString: string) {
    // Adım 1: String'i başlangıç ve bitiş saatleri olarak ayır
    const [startTimeStr, endTimeStr] = timeRangeString.split("-");

    // Adım 2: Başlangıç ve bitiş saatlerini Date nesnelerine dönüştür
    const [startHour, startMinute] = startTimeStr.split(":").map(Number);
    const [endHour, endMinute] = endTimeStr.split(":").map(Number);

    // Bugünün tarihini al
    const today = new Date();

    // Başlangıç saati için tarih nesnesini ayarla
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), startHour, startMinute);

    // Bitiş saati için tarih nesnesini ayarla
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), endHour, endMinute);

    // Adım 3: Tarihleri istediğiniz formata dönüştür (örneğin ISO formatı)
    const startDateFormatted = startDate.toISOString(); // Örnek: "2024-05-11T12:00:00.000Z"
    const endDateFormatted = endDate.toISOString();     // Örnek: "2024-05-11T13:00:00.000Z"

    return { startDate: startDateFormatted, endDate: endDateFormatted };
}


const UpdateActivitySession = () => {

    const { id } = useParams() as { id: string }
    const navigate = useNavigate()
    const [updateActivitySession, { data, isSuccess, isLoading, isError, error }] = useUpdateActivitySessionMutation()
    const [valueStartTime, setValueStartTime] = useState<Dayjs | null>(null);
    const [openStartTime, setOpenStartTime] = useState(false);
    const [valueEndTime, setValueEndTime] = useState<Dayjs | null>(null);
    const [openEndTime, setOpenEndTime] = useState(false);
    const { data: activityData } = useAdminActivityQuery({ id: id ?? '' })

    const departmentsFormik = useFormik({
        initialValues: {
            sessionAllowedDepartments: activityData?.sessionAllowedDepartments.map((department: any) => ({ label: department, value: department })) ?? []
        },
        enableReinitialize: true,
        onSubmit: values => {
            console.log(values, 'values1')
        }
    })

    const nameFormik = useFormik({
        initialValues: {
            sessionName: activityData?.sessionName ?? ''
        },
        enableReinitialize: true,
        onSubmit: values => {
            console.log(values, 'values2')
        }
    })

    const sessionFormik = useFormik({
        initialValues: {
            sessionName: nameFormik.values.sessionName,
            sessionTimeInterval: `${dayjs(valueStartTime).format('HH:mm')}-${dayjs(valueEndTime).format('HH:mm')}`,
            sessionAllowedDepartments: departmentsFormik.values.sessionAllowedDepartments.map((department: any) => department.value)
        },
        enableReinitialize: true,
        onSubmit: values => {
            console.log(values, 'values')
            updateActivitySession({ _id: id, ...values })
        }
    })

    useEffect(() => {
        if (activityData) {
            console.log(activityData?.sessionTimeInterval)

            const dateRange = formatDateRange(activityData?.sessionTimeInterval);
            setValueStartTime(dayjs(dateRange.startDate))
            setValueEndTime(dayjs(dateRange.endDate))
        }
    }, [activityData])


    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Activity has been updated successfully', { variant: 'success' });
            navigate(routes.admin.activities)

        }
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {
            enqueueSnackbar((error as any).error.message.en, { variant: 'error' })
        }
    }, [isError, error])





    return <DashboardLayout>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <ArrowBackIos onClick={() => navigate(-1)} sx={{ cursor: 'pointer' }} />
                        <Typography variant='h3'>Update Activity Session </Typography>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12}>

                <Card sx={{ p: 5, mt: 2 }}>

                    <Grid container spacing={3} mt={1}>

                        <Grid item xs={12} >
                            <Typography variant="h3" >{activityData?.title}</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" >Session Information</Typography>
                            <Divider />
                        </Grid>



                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Session Name"
                                values={nameFormik.values}
                                name={"sessionName"}
                                setFieldValue={nameFormik.setFieldValue}
                                handleBlur={nameFormik.handleBlur}
                                touched={nameFormik.touched}
                                errors={nameFormik.errors}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SelectField
                                values={departmentsFormik.values}
                                name={"sessionAllowedDepartments"}
                                setFieldValue={departmentsFormik.setFieldValue}
                                datas={departments}
                                placeholder="Select Departments"
                            />
                        </Grid>

                        <ClickAwayListener onClickAway={() => setOpenStartTime(false)}>
                            <Grid item xs={6}>
                                <TimeField
                                    label="Start Time"
                                    value={valueStartTime ? dayjs(valueStartTime).format('HH:mm') : ''}
                                    onClick={() => setOpenStartTime(true)}
                                />

                                {openStartTime && <MultiSelectTimeField
                                    value={valueStartTime}
                                    onChange={(newValue) => {
                                        setValueStartTime(newValue)
                                        setValueEndTime(dayjs(newValue).add(1, 'hour'))
                                    }}
                                />}
                            </Grid>
                        </ClickAwayListener>

                        <ClickAwayListener onClickAway={() => setOpenEndTime(false)}>
                            <Grid item xs={6} >
                                <TimeField
                                    label="End Time"
                                    value={valueEndTime ? dayjs(valueEndTime).format('HH:mm') : ''}
                                    onClick={() => setOpenEndTime(true)}
                                />

                                {openEndTime && <MultiSelectTimeField
                                    minTime={valueStartTime ?? dayjs('1991-05-23T00:00')}
                                    value={valueEndTime}
                                    onChange={(newValue) => setValueEndTime(newValue)}
                                />}
                            </Grid>
                        </ClickAwayListener>

                        <Grid item xs={12} md={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="secondary"
                                disabled={!valueStartTime || !valueEndTime || !sessionFormik.values.sessionName || !sessionFormik.values.sessionAllowedDepartments.length || isLoading}
                                onClick={() => {
                                    sessionFormik.handleSubmit()
                                }}
                            >
                                {isLoading ? <CircularProgress size={"1rem"} /> :
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Update sx={{ mr: 1 }} />
                                        <Typography fontWeight={"bold"}>Update Session</Typography>
                                    </Stack>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

            </Grid>
        </Grid>
    </DashboardLayout>
}

export default UpdateActivitySession